import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "searchInput", "dropdownText", "dropdownButton" ]

  changePlaceholderText() {
    if(window.innerWidth < 1024) {
      this.searchInputTarget.placeholder = "Sensibilisation au handicap";
    } else {
      this.searchInputTarget.placeholder = "Sensibilisation au handicap, diversité, inclusion ...";
    }
  }

  changeDropdownText(event) {
    this.dropdownTextTarget.innerText = event.currentTarget.innerText;
    const inactiveButtons = this.dropdownButtonTargets.filter(function(button) { return button !== event.currentTarget; });
    inactiveButtons.forEach((button) => {
      button.classList.remove("active");
      button.ariaExpanded = false;
      button.ariaSelected = false;
    });
  }
}
