import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["topButton"]

  scrollIntoView(event) {
    const anchor = document.querySelector('#' + event.currentTarget.dataset.legalChapter);
    anchor.scrollIntoView();
  }

  display() {
    if (window.scrollY > 0) {
      this.topButtonTarget.classList.remove("legal-layout__top-btn--display-none");
    } else {
      this.topButtonTarget.classList.add("legal-layout__top-btn--display-none");
    }
  }

  scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
