import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start();
// Turbolinks.start();
ActiveStorage.start();

// ----------------------------------------------------
import { handleNavScroll } from "../components/handle_nav_scroll";

// document.addEventListener('turbolinks:load', () => {
document.addEventListener('load', () => {
    handleNavScroll();
});

import { Application } from "stimulus"
import AnchorController from "../controllers/anchor_controller"
import ScrollController from "../controllers/scroll_controller"
import NavbarController from "../controllers/navbar_controller"
import HomeController from "../controllers/home_controller"
import InterventionController from "../controllers/intervention_controller"

const application = Application.start();
application.register("anchor", AnchorController);
application.register("scroll", ScrollController);
application.register("navbar", NavbarController);
application.register("home", HomeController);
application.register("intervention", InterventionController);

import 'bootstrap';
