export default class AnchorManager {
    _base16 = '0A12B34C56D78E9F'
    _prefix = ''

    constructor(prefix) {
        this._prefix = prefix
    }

    decode(str) {
        let urlDecoded = "";
        for (let i = 0; i < str.length; i += 2) {
            let ch = this._base16.indexOf(str.charAt(i));
            let cl = this._base16.indexOf(str.charAt(i + 1));
            urlDecoded += String.fromCharCode((ch * 16) + cl);
        }
        return urlDecoded;
    }

    /**
     * data-u: encoded url
     * data-t: target
     * data-c: classes css
     * @param tags
     */
    decodeAll(tags) {
        tags.forEach((tag) => {
            let encodedUrl = tag.dataset.u.toString();
            if(encodedUrl.startsWith(this._prefix)) {
                let url = this.decode(encodedUrl.substring(this._prefix.length));
                let nlink = document.createElement("a");
                nlink.href = url;
                nlink.target = tag.dataset.t || "_self";
                nlink.className = tag.dataset.c || "";
                if (tag.dataset.title) {
                    nlink.title = tag.dataset.title;
                }

                tag.removeAttribute("data-u");
                tag.removeAttribute("data-t");
                tag.removeAttribute("data-c");

                Object.values(tag.attributes).forEach((attr) => {
                    switch (true) {
                        case attr.name.startsWith("aria-"):
                        case attr.name.startsWith("data-"):
                        case ["rel"].includes(attr.name):
                            nlink.setAttribute(attr.name, attr.value);
                            tag.removeAttribute(attr.name);
                    }
                });

                $(tag.childNodes).each(function() {
                    nlink.appendChild(this);
                });
                tag.replaceWith(nlink);
            }
        });
    }
}
