import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["formatLink"];

    changeFormat(event) {
        event.preventDefault();
        localStorage.setItem('format', event.target.dataset.format);
        window.location.href = event.target.href;
    }
}

